// 登录接口
import {
	axios
} from '@/utils/http.js'

const UpgradeApi = {
	UpgradeList: '/v3/ProductUpgrade/List',//列表
	UpgradeDel: '/v3/ProductUpgrade/Del',//删除
	UpgradeAdd: '/v3/ProductUpgrade/Add',//添加
	Upgrademodel: '/v3/ProductUpgrade/T_model_List',//产品型号select
}
//添加
export function UpgradeAdd(data) {
	return axios({
		url: UpgradeApi.UpgradeAdd,
		method: 'post',
		data:data
	})
}
//产品型号select
export function Upgrademodel(data) {
	return axios({
		url: UpgradeApi.Upgrademodel,
		method: 'post',
		data:data
	})
}
//删除
export function UpgradeDel(data) {
	return axios({
		url: UpgradeApi.UpgradeDel,
		method: 'post',
		data:data
	})
}
//列表
export function UpgradeList(data) {
	return axios({
		url: UpgradeApi.UpgradeList,
		method: 'post',
		data:data
	})
}
