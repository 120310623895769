<template>
	<div class="files">
		<div class="files-mai">
			<div class="files-mai1">
				<img src="@/assets/img/flies.png" alt="" srcset="">
				{{ flieAccept }} 
				<div v-show="values==''">点击虚线框内，<span style="color: #409EFF;">上传文件</span></div>
				<div v-show="values!=''">恭喜你，<span style="color: #409EFF;">文件已上传成功</span></div>
			</div>
			<!-- :accept="flieAccept?flieAccept:'application/octet-stream'" -->
			<input type="file" @change="files"
				style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity:0;cursor: pointer;"/>
		</div>
	</div>
</template>

<script>
	import {
		PDFToken
	} from "@/api/pdfToken.js";
	import * as qiniu from 'qiniu-js';
	export default {
		props:['flieAccept'],
		data() {
			return {
				values:'',
				file: null, //点击上传的pdf资源包
				qiniuToken: null,
			}
		},
		methods: {
			files(e) { //上传图片到七牛云拿url
				//console.log(e)
				var _this = this
				this.file = e.target.files[0];
				var strtype = this.file.name.substring(this.file.name.lastIndexOf('.') + 1); //获取后缀 png jpg
				PDFToken({
					T_suffix: strtype
				}).then(res => {
					if (res.data.Code == 200) {
						//console.log('token',res)
						_this.qiniuToken = res.data.Data
						_this.QiniuYun()
					} else {
						_this.$message.error(res.data.Msg);
					}
				})
			},
			QiniuYun() {
				
				const loading = this.$loading({
					lock: true,
					text: '正在上传文件，请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				var _this = this
				var key = _this.file.name || null;
				var config = {
					useCdnDomain: false, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
					region: qiniu.region.z2,
					domain: "https://qiniu.region.z2", //配置好的七牛云域名  如   https://cdn.qniyun.com/
					chunkSize: 1000, //每个分片的大小，单位mb，默认值3
					forceDirect: false //直传还是断点续传方式，true为直传
				};
				var putExtra = {
					fname: _this.file.name, //文件原始文件名
					params: {},
					mimeType: [] || null
				};
				var observable = qiniu.upload(_this.file, key, _this.qiniuToken);
				// var observable = qiniu.upload(_this.file, key, _this.qiniuToken, putExtra, config);
				//   设置实例的监听对象
				var observer = {
					next(res) {
						//上传进度
						_this.filePercent = parseInt(res.total.percent)
						if (_this.filePercent === 100) {
							//console.log('实例监听对象', res)
						}
						//
					},
					// 接收上传错误信息
					error(err) {
						loading.close()
						switch (err.code) {
							case 413:
								_this.$message.error('错了哦，图片可能太大了哦');
								break
							case 408:
								_this.$message.error('错了哦，请求超时');
								break
							default:
								_this.$message.error('错了哦，上传错误');
						}
					},
					// 接收上传完成后的信息
					complete(com) {
						//console.log('七牛云返回',com)
						loading.close()
						_this.$emit('pdfvalue', com.key);
						_this.values = com.key
					}
				};
				observable.subscribe(observer);
			},
		}
	}
</script>

<style scoped lang="scss">
	.files-mai {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		position: relative;
		height: 150px;
		max-width: 250px;
		.files-mai1 {
			height: 100%;
			color: #606266;
			font-size: 14px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				width: 60px;
				height: 60px;
			}
		}
	}

	.files-mai:hover {
		border: 1px dashed #409EFF;
	}
</style>
