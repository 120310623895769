<template>
	<div class="UpgradedVersion">
		<div class="UpgradedVersion-T">
			<div class="UpgradedVersionL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @selects="inputValue" :selectShow="true" :options="options" selectPlace="请选择版本"></inputs>
					</div>
					<div class="UpgradedVersionit-mai1-btn">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="UpgradedVersionR">
				<div class="RightsManagement-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
					<el-button type="primary" v-permission="'Upgraded:Add'" @click="clickNew()" icon="el-icon-plus">新增版本</el-button>
				</div>
			</div>
		</div>
		<div class="UpgradedVersion-B">
			<el-table :data="tableData" border style="width: 100%" size="small">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column prop="T_model" label="产品型号" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="版本" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_version}}
					</template>
				</el-table-column>
				<el-table-column label="文件" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_file}}
					</template>
				</el-table-column>
				<el-table-column label="备注" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_remarks}}
					</template>
				</el-table-column>
				<el-table-column label="时间" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.CreateTime}}
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<el-button size="small" v-permission="'Upgraded:Del'" @click="allpir(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 50px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="新增版本" :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
					<el-form-item label="版本" prop="T_version">
						<el-input v-model="ruleForm.T_version"></el-input>
					</el-form-item>
					<el-form-item label="产品型号" prop="T_model">
						<el-select v-model="ruleForm.T_model" placeholder="请选择产品型号">
						  <el-option v-for="item in newoptions" :key="item.value" :label="item.label" :value="item.value">
						  </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="全量发布" prop="T_carryout">
						<el-radio-group v-model="ruleForm.T_carryout">
							<el-radio label="1">是</el-radio>
							<el-radio label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="上传文件"><!-- prop="T_file" -->
						<files @pdfvalue="fliValue"></files>
						<div style="line-height: 20px;margin-top: 5px;font-size: 12px;">{{ruleForm.T_file}}</div>
					</el-form-item>
					<el-form-item label="备注" prop="T_remarks">
						<el-input type="textarea" v-model="ruleForm.T_remarks"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">立即添加</el-button>
						<el-button @click="dialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import files from '@/components/files.vue'
	import {
		UpgradeList,
		Upgrademodel,
		UpgradeDel,
		UpgradeAdd
	} from '@/api/AdminManagement/UpgradedVersion.js'
	export default {
		components: {
			files
		},
		data() {
			return {
				pages: {
					T_model: '',
					page: 1,
					page_z: 10
				},
				total:0,
				options: [],
				newoptions: [],//新增里面的select（不需要选择全部）
				tableData: [],
				dialogVisible: false,
				ruleForm: {
					T_model: '',//产品型号
					T_version: '',//版本
					T_file: '',//文件
					T_remarks: '',//备注
					T_carryout: '0',//全量发布
				},
				rules: {
					T_version: [{
						required: true,
						message: '请输入版本号',
						trigger: 'change'
					}],
					T_model: [{
						required: true,
						message: '请选择产品型号',
						trigger: 'change'
					}],
					T_file: [{
						required: true,
						message: '请上传文件',
						trigger: 'change'
					}],
					T_remarks: [{
						required: true,
						message: '请输入备注',
						trigger: 'change'
					}],
					T_carryout: [{
						required: true,
						message: '请选择是否全量发布',
						trigger: 'change'
					}],
				}
			}
		},
		mounted() {
			this.GetUpgradeListApi()
			this.GetUpgrademodelApi()
		},
		methods: {
			handleSizeChange(e) { //每页多少条
				////console.log('每页多少条', e)
				this.pages.page_z = e
				this.GetUpgradeListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetUpgradeListApi()
			},
			GetUpgrademodelApi(){
				Upgrademodel({}).then(res => {
					////console.log('select', res)
					if (res.data.Code === 200) {
						var arr = [{
							value: '',
							label: '全部型号'
						}]
						var arr1 = []
						res.data.Data.forEach(obj=>{
							arr.push({
								value: obj,
								label: obj
							})
							arr1.push({
								value: obj,
								label: obj
							})
						})
						this.newoptions = arr1
						this.options = arr
					}
				})
			},
			GetUpgradeListApi() {
				UpgradeList(this.pages).then(res => {
					////console.log('列表', res)
					if (res.data.Code === 200) {
						this.tableData = res.data.Data.Data
						this.total = res.data.Data.Num
					}
				})
			},
			fliValue(e) {
				this.ruleForm.T_file = e
			},
			handleClick(tab, event) {
				////console.log(tab, event)
			},
			allpir(item ) {
				this.$confirm('此操作将执行删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					UpgradeDel({
						T_id:item.Id
					}).then(res=>{
						////console.log('删除',res)
						if(res.data.Code===200){
							this.$message.success('操作成功，已经删除')	
							this.GetUpgradeListApi()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.SetUpgradeAddApi()
					} else {
						return false;
					}
				});
			},
			SetUpgradeAddApi(){//
				UpgradeAdd(this.ruleForm).then(res=>{
					if(res.data.Code===200){
						this.$message.success('操作成功，已成功添加')	
						this.pages.page = 1
						this.GetUpgradeListApi()
						this.dialogVisible = false
					}
				})
			},
			inputValue(e) {
				this.pages.T_model = e
				this.pagespage = 1
				this.GetUpgradeListApi()
			},
			clickNew() { //新增
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				this.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss">
	.UpgradedVersion {
		.UpgradedVersion-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.UpgradedVersionL {
				display: flex;
				align-items: center;

				.RightsManagement-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.UpgradedVersionit-mai1-btn {}

					.RightsManagement-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.RightsManagement-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.UpgradedVersion-B {
			background: rgba(#fff, 1);
			margin: 10px;
			padding: 20px 30px;
		}
	}
</style>
